<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="wp">
                <Navbar></Navbar>

                <div class="white-bg ">
                    <div class="news-main">
                        <div class="div_ExamList_all com_Box">
                            <div class="Tit">
                                <div class="Tit-title">
                                    考试分类
                                </div>
                            </div>
                            <div class="List">
                                <div v-for="(item, i) in bigTypeList" :key="i" class=" div_enrollList"
                                >
                                    <div class="div_enrollList_tag tag-success" style="display: inline-block"><span
                                            class="enrollList-text line1" :title="item.lbmc">{{item.lbmc}}</span></div>
                                    <div class=" div_enrollList_center line1" v-for="(item2,i) in item.data" :key="i"
                                         @click="goToPlan(item2.flbm, item2.flmc)">{{item2.flmc}}
                                    </div>
                                    <!--<div class="div_ExamList_time"><i class="iconfont icon-shijian mr-2"></i>报名时间：{{item.bmkssj}}至{{item.bmjssj}}</div>-->
                                    <!--<div class="div_ExamList_button  ExamList_button_info"><span class="enrollList-text">{{item.isOpen ? '正在报名': '报名结束'}}</span></div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from "./index-header.vue"
    import Footer from "./index-footer.vue"
    import Navbar from "./navbar.vue"
    import commonPagination from "../common/commonPagination";

    import {getSecondList} from "@/api/web/web.js";

    export default {
        name: "index",
        data() {
            return {
                secondList: [],
                bigTypeList: []
            }
        },
        components: {
            Header,
            Navbar,
            Footer,
            commonPagination
        },
        methods: {
            /**
             * 获取考试二级分类
             */
            getList() {
                getSecondList().then((res) => {
                    if (res.status) {
                        let list = res.data;
                        this.bigTypeList = this.groupData(list, "lbmc")

                        this.secondList = list


                    }
                });
            },

            /**
             * 跳转考试计划
             */
            goToPlan(flbm, flmc) {
                let routeUrl = this.$router.resolve({
                    path: "/examPlan",
                    query: {
                        flbm: flbm,
                        flmc: flmc
                    },
                });
                window.open(routeUrl.href, "_selft");
            },
            /**
             * 改变数据结构
             */
            groupData(arr, key) {
                const groups = {}
                arr.forEach((el) => {
                    const group = el[key]
                    groups[group] = groups[group] || []
                    groups[group].push(el)
                })
                return Object.keys(groups).map((group) => {
                    // 更改data的数据结构  可以改变新数据的结构 data即为newData的数组中每个数据的结构
                    let data = {
                        lbmc: group,
                        data: groups[group]
                    }
                    return data
                })
            },


        },
        mounted() {
            this.getList()
        }
    };
</script>

<style>
    @import '~@/assets/css/common.css';
    @import '~@/assets/css/index.css';
    @import '~@/assets/css/list.css';
</style>
<style type="text/css" scoped>
    .center-list-title .iconfont {
        color: #2886bd;
    }

    .news-main {
        min-height: 550px;
    }

    .div_enrollList_tag {
        width: auto !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .div_enrollList {
        height: auto;
    }

    .div_enrollList:hover {
        background-color: transparent;
    }

    .div_enrollList_center {
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
    }

    .div_enrollList_center:hover {
        background-color: #eaf0f7;
    }
</style>
